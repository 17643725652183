import React, { useState, useEffect, useRef } from "react";
import { graphql, Link } from "gatsby";
import { isWindow } from "../components/Helpers";
import {
	EdetailSafetyStyles,
	MobileSurveyMgntChartStyles,
} from "../styles/EdetailSafetyStyles";
import { AudioTranscript } from "../components/AudioTranscript";
import { interaction } from "../components/Helpers";
import {
	Container,
	Footnotes,
	ConvertedMarkdown,
	Definitions,
	References,
	TabbingThrough,
} from "../components";

import { SectionCarousel } from "../components/Carousels/SectionCarousel";

const EdetailSafety = ({ data }) => {
	const slider_1 = useRef();
	const slider_2 = useRef();
	const [isHCP, setHCP] = useState(false);

	const dataQuery = data.allMarkdownRemark.nodes[0],
		componentID = data.allMarkdownRemark.nodes[0].frontmatter.components_id,
		markdownHTML = data.allMarkdownRemark.nodes[0].html,
		arrowText_1 =
			data.allMarkdownRemark.nodes[0].frontmatter.arrowText_1[0],
		arrowText_2 =
			data.allMarkdownRemark.nodes[0].frontmatter.arrowText_2[0],
		audioTracksWithProps =
			data.allMarkdownRemark.nodes[0].frontmatter.audioTracksWithProps[0],
		tabComponentItems =
			data.allMarkdownRemark.nodes[0].frontmatter.tabComponent,
		isiSnippet = data.allMarkdownRemark.nodes[0].frontmatter.isiSnippet,
		markdownID = `ed-safety-content`;

	const [transcriptState, setTranscriptState] = useState(false);
	const [trackWProps, setTrackWProps] = useState("default");

	useEffect(() => {
		if (isWindow) {
			let isHCP = sessionStorage.getItem("hcp");
			if (isHCP !== "true") {
				setHCP(false);
			} else {
				setHCP(true);
			}
			let adverseReactionsWrapper = document.getElementById(
				"adverse-reactions-wrapper"
			);
			if (adverseReactionsWrapper) {
				adverseReactionsWrapper.addEventListener(
					interaction,
					function (e) {
						e.stopPropagation();
					}
				);
			}
		}
	}, [isHCP, setHCP]);

	const manipulateDOM = () => {
		if (isWindow) {
			if (window.screen.width < 768) {
				let carousel2 = document.querySelector(".carousel-2");
				let panel3 = document.querySelectorAll("[data-index='1']")[2];
				let mSurveyChart = document.getElementById("m-survey-mgnt-chart");
				if (
					carousel2 && carousel2.classList.contains("visible") &&
					panel3 && panel3.classList.contains("slick-current") &&
					mSurveyChart
				) {
					mSurveyChart.getElementById("m-survey-mgnt-chart").classList.remove("hide");
				}
			}
		}
	};

	useEffect(() => {
		manipulateDOM();
	});

	const sTabsSetTrackWProps = (next) => {
		let trackKey = ["default", "time", "management"];
		setTrackWProps(trackKey[next]);
	};
	const sSlides1SetTrackWProps = (next) => {
		let trackKey = ["default", "detected", "adverse"];
		setTrackWProps(trackKey[next]);
	};
	const sSlides2SetTrackWProps = (next) => {
		let trackKey = ["management", "survey"];
		setTrackWProps(trackKey[next]);
	};

	const MobileSurveyMgntChart = () => {
		const selectionHandler = (e) => {
			e.preventDefault();

			const _surveyChartSelectors = document.querySelectorAll(
				"#m-survey-mgnt-chart div > a"
			);
			const _managementChartElem =
				document.getElementById("management-chart");
			const managementChart = e.target.dataset.chart;

			_surveyChartSelectors.forEach((el) => {
				if (el.classList.contains("active")) {
					el.classList.remove("active");
				}
			});

			e.target.classList.add("active");

			_managementChartElem.src = `/images/` + managementChart + `.svg`;
		};

		return (
			<MobileSurveyMgntChartStyles
				id={`m-survey-mgnt-chart`}
				className={`hide`}
			>
				<div>
					<Link
						to={`#`}
						data-selector={`one`}
						data-chart={`increased-coughing-mobile-chart`}
						className={`btn-5 active`}
						onClick={selectionHandler}
						onKeyDown={selectionHandler}
					>
						Increased
						<br />
						coughing
					</Link>
					<Link
						to={`#`}
						data-selector={`two`}
						data-chart={`dyspnea-chart-mobile`}
						className={`btn-5`}
						onClick={selectionHandler}
						onKeyDown={selectionHandler}
					>
						Dyspnea
					</Link>
				</div>
				<div>
					<Link
						to={`#`}
						data-selector={`three`}
						data-chart={`dysphonia-chart-mobile`}
						className={`btn-5`}
						onClick={selectionHandler}
						onKeyDown={selectionHandler}
					>
						Dysphonia
					</Link>
					<Link
						to={`#`}
						data-selector={`four`}
						data-chart={`increased-sputum-chart-mobile`}
						className={`btn-5`}
						onClick={selectionHandler}
						onKeyDown={selectionHandler}
					>
						Increased
						<br />
						sputum
					</Link>
				</div>

				<img
					id={`management-chart`}
					src={`/images/increased-coughing-mobile-chart.svg`}
					alt={``}
				/>
			</MobileSurveyMgntChartStyles>
		);
	};
	return (
		<>
			<Container
				markdownID={markdownID}
				componentID={componentID}
				query={dataQuery}
				isiSnippet={isiSnippet}
				trackWProps={audioTracksWithProps[trackWProps]}
				setTrackWProps={setTrackWProps}
				transcriptState={transcriptState}
				setTranscriptState={setTranscriptState}
			>
				<EdetailSafetyStyles id={`main`} className={`wrapper`}>
					<TabbingThrough
           id={`tabbing-through`}
						className={"safety-tabs"}
						resetSlides={(tabIndex) => {
							if (tabIndex === 0) {
								slider_1.current.slickGoTo(0);
							}
						}}
						tabs={tabComponentItems}
						setTrackWProps={sTabsSetTrackWProps}
						setTranscriptState={setTranscriptState}
					/>
					<SectionCarousel
						className={`safety-carousel carousel-1 show visible margin`}
						arrowText={arrowText_1.copy}
						arrowTextISI={arrowText_1.isiSnippets}
						markdownID={markdownID}
						slider={slider_1}
						slidesYouWantToMake={
							dataQuery.frontmatter.sectionSlides_1
						}
						dataOptions={`1`}
						setTrackWProps={sSlides1SetTrackWProps}
					/>
					<ConvertedMarkdown
						markdownID={markdownID}
						mdhtml={markdownHTML}
					/>
					<SectionCarousel
						className={`safety-carousel carousel-2`}
						arrowText={arrowText_2.copy}
						arrowTextISI={arrowText_2.isiSnippets}
						markdownID={markdownID}
						slider={slider_2}
						slidesYouWantToMake={
							dataQuery.frontmatter.sectionSlides_2
						}
						dataOptions={`3`}
						setTrackWProps={sSlides2SetTrackWProps}
					/>
					<MobileSurveyMgntChart />
					<Footnotes
						className={markdownID}
						isBottom={true}
						footnotes={dataQuery.frontmatter.footnotes}
					/>
					<Definitions
						definitions={dataQuery.frontmatter.definitions}
					/>
					<References references={dataQuery.frontmatter.references} />
					<AudioTranscript
						id={`audio-transcript-main`}
						transcriptState={transcriptState}
						setTranscriptState={setTranscriptState}
						transcriptCopy={
							audioTracksWithProps[trackWProps].transcript
						}
					/>
				</EdetailSafetyStyles>
			</Container>
		</>
	);
};

export const query = graphql`
	{
		allMarkdownRemark(
			filter: {
				frontmatter: { markdown_id: { eq: "ed-safety-content" } }
			}
		) {
			nodes {
				frontmatter {
					slug
					description
					keywords
					markdown_id
					title
					components_id
					definitions
					isiSnippet
					injectFootnotes {
						container
						content
					}
					footnotes
					references
					audioTracksWithProps {
						default {
							trackID
							isiContent
							src
							title
							transcript
						}
						detected {
							trackID
							isiContent
							src
							title
							transcript
						}
						adverse {
							trackID
							isiContent
							src
							title
							transcript
						}
						time {
							trackID
							isiContent
							src
							title
							transcript
						}
						management {
							trackID
							isiContent
							src
							title
							transcript
						}
						survey {
							trackID
							isiContent
							src
							title
							transcript
						}
					}
					sectionSlides_1
					sectionSlides_2
					tabComponent {
						title
						isiSnippets
					}
					arrowText_1 {
						copy
						isiSnippets
					}
					arrowText_2 {
						copy
						isiSnippets
					}
				}
				id
				html
			}
		}
	}
`;

export default EdetailSafety;
