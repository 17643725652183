import styled from "styled-components/macro";
import { device } from "../components/Helpers";

export const EdetailSafetyStyles = styled.main`
position: relative;
    margin: 12.5rem auto 0;
@media ${device.mobile} {
  position:relative;
  padding-top: 6rem !important;
}
#tabbing-through{
  @media ${device.mobile} {
    width:96% !important;
	z-index: ${(props) => (props.isZoomed ? "-1" : "3")} !important;
  }
}

#safety-tabs{
  @media ${device.tablet} {
    margin-top:15.8rem;
   }
   @media ${device.mobile} {
   z-index:2;
   width:96% !important;
   }
}

li {
  @media ${device.mobile} {
    font-size:1.33rem;
   }
}

.treatmentwrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom:30px;


  @media ${device.mobile} {
    flex-direction:column;
   }


  @media ${device.mobile} {
    flex-direction:column;
   }

  h2 {
    line-height: 5rem;
    text-align: center;
    padding-bottom:28px;
    @media ${device.mobile} {
      margin-bottom:2rem;
      text-align:left;
     }
     @media ${device.tablet} {
     line-height:1.43rem;
     margin-top:1rem;
     }
  }

  .treatment2 > h2 {
    @media ${device.mobile} {
     line-height:1.9rem;
     margin-top:3.33rem;
     margin-bottom:2.5rem;
     }
     @media ${device.tablet} {
      line-height:1.45rem;
      margin-top:1rem;
      margin-bottom:2.5rem;
      }
  }

  .treatment2 > .calloutwrapper > .iconwrappers > h2{
    @media ${device.mobile} {
      margin-bottom:0;
      padding-bottom:0;
      }
  }

  #broncho-h2{
    @media ${device.mobile} {
      text-align:center;
      padding-bottom:0;
      margin-bottom:0;
      };
  }

  .icons {
    background: #fff;
    margin: -4rem auto 0;
    position: relative;
    text-align:center;
    top: 0;
    width: 5rem;
	height: 62px;
    display: flex;
    flex-direction: column;

    img {
      text-align:center;
      margin: auto;
    }
  }
}

.treatment1 > div {
  @media ${device.tablet} {
    margin-top:2rem;
   }
}

.calloutwrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media ${device.mobile} {
   flex-direction:column;
  }
  @media ${device.tablet} {
    margin-top:-2rem;
   }
  & > div {
    width: 50%;
    display: flex;
    text-align: center;

    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    @media ${device.mobile} {
      width: 90%;
      margin-bottom:3.33rem;
    }
  }
  & > div:nth-child(2){
    @media ${device.mobile} {
      margin-top:1rem;
    }
  }
}

.md-buffer {
  margin: 1.25rem 0;
}

#teaes-over-time {
  a {
    color:#262626;
  }
}

.definitions {
  margin:0 0;
  line-height:0.875rem;
  letter-spacing: 0.01071em;
}

.tea-chart {
	margin: 25px auto;
  max-width: 100%;
  height: auto;
  width: 833px;
}
section{
  margin-top:0;
}
section[data-tab-index="1"], section[data-tab-index="2"],section[data-tab-index="3"]{
  padding-top: 2.375rem;
  @media ${device.desktop} {
    padding-top: 3.5rem;
  }
  @media ${device.desktop_lg} {
    padding-top: 3.5rem;
  }
  @media ${device.desktop_xlg} {
    padding-top: 1rem;
  }
  @media ${device.tablet} {
    padding-top: 3.375rem;
  }
  @media ${device.mobile} {
    margin-top: -2.5rem;
  }
}
.safety-carousel {
  @media ${device.desktop} {
    margin:0;
  }
  @media ${device.tablet} {
    margin:0;
  }
  h1 {
    @media ${device.tablet} {
      line-height:1.7rem;
    }
  }
}

section[data-tab-index="2"]{
  @media ${device.mobile} {
    margin-top:6.5rem !important;
    margin-bottom: 0 !important;
  }
  h1 {
    @media ${device.mobile} {
      margin-bottom:1.7rem !important;
    }
  }
  h2:nth-of-type(2){
    @media ${device.mobile} {
      margin-top:3.33rem !important;
      margin-bottom:1.7rem !important;
    }
  }
}


  #m-survey-mgnt-chart.hide {
    display:none;
  }

  #m-survey-mgnt-chart {
   @media ${device.mobile} {
    margin-top: -87rem;
   z-index:98;
    }
  }

  

section[data-tab-index="3"] {
  section[data-index="1"]{
    #m-survey-management-container{
      display:none;
      margin-top:4.9rem;
      @media ${device.mobile} {
        display:block;
        height:67rem;
       }
       #m-survey-mgnt-chart > .management-m-row{
        @media ${device.mobile} {
          display:flex;
         }
         a.btn-5 {
          @media ${device.mobile} {
            display:flex;
            align-items:center;
            justify-content:center;
            width:11.25rem;
            height:4.25rem;
           }
           &[data-selector="one"]{
            @media ${device.mobile} {
             margin-right:1rem;
             margin-bottom:1rem;
            }
           }
           &[data-selector="three"]{
            @media ${device.mobile} {
             margin-right:1rem;
             margin-bottom:1rem;
            }
           }
         }
       }
    }
    #d-survey-management-image {
      padding-bottom:1.5rem;
      width:100%;
      @media ${device.mobile} {
        display:none;
       }
    }
  }
  .slick-slider {
    @media ${device.mobile} {
      width: 84vw !important;
    }
  }
   div > div > div > div.slick-slide.slick-active.slick-current > div > section {
    @media ${device.mobile} {
      margin-top:8.5rem;
    }
   }
   [data-index="1"].slick-slide:not(.slick-active) {
     width:400px!important;
     @media ${device.mobile} {
      width: 84vw !important;
    }
   }
}

#no-new-signals {
  h2 {
    font-weight:500;
    font-size:1.75rem;
    margin-bottom:1.938rem;
    @media ${device.mobile} {
      font-size:2.25rem;
      line-height:27px;
    }
  }
  .text-wrapper {
    p {
      margin-bottom:1rem;
    }
    margin-bottom:2rem;
  }
  .tiny {
    font-size:.875rem;
    line-height:1;
    color:${(props) => props.theme.colors.brand_black_00};
    @media ${device.mobile} {
      font-size:1rem;
      line-height:1.167rem;
    }
  }
}

section[data-tab-index="1"]{
  div > div > div > div.slick-slide.slick-active.slick-current {
    @media ${device.mobile} {
      width: 84vw !important;
    }
}



       .slick-slide[data-index="1"] {
          @media ${device.mobile} {
            width: 84vw !important;
          }
          section {
            @media ${device.mobile} {
              margin-bottom:2rem;
            }
          }
       }

       .slick-slide[data-index="2"] {
        @media ${device.mobile} {
          width: 84vw !important;
        }
     }

     .slick-slider {
      @media ${device.mobile} {
        width:84vw !important;
      }
     }


section[data-tab-index="3"]{
  h2 {
    line-height:1.75rem;
  }
 div > div > div > div.slick-slide.slick-active.slick-current {
  @media ${device.desktop} {
    width:58.44rem !important;
  }
  @media ${device.tablet} {
    width:52.5rem !important;
  }
  @media ${device.mobile} {
    margin-bottom:2rem;
  }
 }


 .slick-track {
  @media ${device.desktop} {
    width:940px !important;
    transform: translate3d(0px, 0px, 0px) !important;
  }
  @media ${device.tablet} {
    width:830px !important;
    transform: translate3d(0px, 0px, 0px) !important;
  }
  @media ${device.desktop_lg} {
    width:2120px !important;
    transform: translate3d(0px, 0px, 0px) !important;
  }

 }

 div[data-index="1"]{
   h2{
  font-weight:500;
  font-size:1.75rem;

  &.md-buffer{
    margin-top:1.875rem;
  }
   }
 }
 
 .slick-slide {
   display:none;
   &.slick-active {
     display:block;
   }
 }
 #d-survey-management-image {
  @media ${device.desktop} {
    width:100%;
    margin:2.5rem auto;
  }
  @media ${device.tablet} {
    width:100%;
    margin:2.5rem auto;
  }
  @media ${device.mobile} {
   display:none;
  }
 }
}



  h1 {
    @media ${device.mobile} {
      margin-bottom: 1rem;
    }
  }
  .modal-popup-content {
    @media ${device.mobile} {
      padding: 1rem;
    }
    .warnings-header {
      @media ${device.mobile} {
        padding-right: 2.5rem;
        font-size: 1.9167rem;
      }
    }
  }

  .md-buffer {
    margin-bottom: 1.25rem;
  }
  #d-survey-management-image {
    @media ${device.mobile} {
      display: none !important;
    }
  }

 

  .safetyimg {
    margin: 2.5rem auto 2.375rem auto;
    @media ${device.tablet} {
      width:100%;
    }
  }

  .safetywrapper {
    margin-top:3rem;
  }


  #clinical-trial-safety, .safety-carousel{
    /* margin-top:7rem; */
    @media ${device.mobile} {
      margin-bottom: 2.5rem;
    }
    img {
      width: 100%;
      &.safetyimg {
        margin-top: 2.5rem;
        padding-left: 7rem;
        padding-right: 6.125rem;
        @media ${device.mobile} {
          padding: 0;
          width: 500px;
          margin-top: 1rem;
        }
      }
    }

    .svg5 {
      width: 8.75rem;
      left: 44%;
      /* margin: 0 auto; */
      display: block;
      position: relative;
      top: -2.5rem;
      margin-bottom: -1rem;

      @media ${device.mobile} {
        left: 0;
        margin: 0 auto;
      }
    }

    .calloutbox1 {
      margin-top: 2rem;
      padding-left: 4.6875rem;
      padding-right: 5.9375rem;
      padding-bottom: 2.5rem;
      @media ${device.mobile} {
        padding-left: 1.1667rem;
        padding-right: 0.5rem;
      }
      h2 {
        margin-bottom: 0.6875rem;
        @media ${device.mobile} {
          font-size: 1.6667rem;
          margin-bottom: 1.6667rem;
        }
      }
      p {
        margin-bottom: 1.25rem;
      }

      div {
        ul {
          list-style: none;
          li {
            margin-bottom: 0.5rem;

            &:last-child {
              margin-bottom: 0;
            }
            &::before {
              content: "•";
              display: inline-block;
              width: 1rem;
              margin-left: -1rem;
              margin-top: -0.2rem;
              @media ${device.mobile} {
                width:.333rem;
                margin-top:0;
                top:-3px;
              }
            }
          }
        }
      }
    }
    section[data-index="2"]{
      
    }
  }
  #additional-adverse-footnotes-content {
    > div:first-of-type {
      margin: 2.5rem 0 7.5rem 0;
    }
  }
  .safety-list-container {
    display: flex;
    margin: 0;
    justify-content: space-between;
    @media ${device.mobile} {
      flex-direction: column;
    }
    ul {
      li {
        @media ${device.mobile} {
          font-size: 1.3333rem;
        }
      }
    }
    & > div:first-child {
      @media ${device.mobile} {
        margin-bottom: 0.5rem;
      }
    }
  }
  .list-container {
    display: flex;
    margin: 2rem;
    margin-left: 10.5625rem;
    margin-right: 12.6875rem;
    @media ${device.mobile} {
      margin-left: 0;
      margin-right: 0;
      margin-top: 3.333rem;
      margin-bottom: 2.5rem;
    }
    @media ${device.desktop} {
      margin-left:0;
    }
    @media ${device.tablet} {
      margin-left:0;
    }
    img {
      @media ${device.mobile} {
        width: 100%;
      }
    }
  }

  .smalltext p {
    margin-bottom: 2.5rem;

    span.no-anchor > a {
      color: inherit;
      cursor: default;
      text-decoration: none;
    }
  }

  .marBot25 {
    margin-bottom: 3.75rem;
  }

  #teaes-over-time {

    h2 {
      margin-bottom: 1.25rem;
    }
    ul {
      margin-bottom: 0.75rem;
      list-style: none;
      li {
        @media ${device.mobile} {
          font-size: 1.33333rem;
        }
        &::before {
          content: "•";
          color: red;
          display: inline-block;
          width: 1rem;
          margin-left: -1rem;
          margin-top: -0.2rem;
        }
      }
    }
    .flexbtn {
      display: flex;
      justify-content: end;
      margin-bottom: 2.5rem;
    }
    a.btn-2 {
      width: fit-content;
      padding: 0.7rem 4rem 0.7rem 1.5rem;
      &:after {
        line-height: 2rem;
      }
    }
    .text-wrapper {
      p {
        margin-bottom: 0.75rem;
      }
    }

    .smalltext {
      p {
        @media ${device.mobile} {
          font-size: 1.0833rem;
          overflow-wrap: break-word;
        }
      }
    }
  }

  .treatmentwrapper {
    display: flex;
    margin-top: 1.875rem;
    margin-bottom: 3.75rem;
    @media ${device.mobile} {
      flex-direction: column !important;
    }
  }

  div.treatment1 > div > h2 {
    @media ${device.mobile} {
      text-align:center;
    }
  }

  // #management-strategies
  
  section[data-index="0"]{
    & > img {
      display: block;
      margin: 0 auto 3.75rem;
      width: 96%;
    }
    & > ul {
      & > li {
        @media ${device.mobile} {
          font-size: 1.3333rem;
        }
      }
    }
    h1 {
      line-height:1.75rem;
    }
    h2 {
      margin-bottom: 1.9375rem;
      &.md-buffer {
        font-weight:500;
        font-size:1.75rem;
      }
    }
    h3 {
      @media ${device.mobile} {
        font-size: 1.3333rem;
        margin-bottom: 1rem;
      }
    }
    ul {
      margin-bottom: 3.75rem;
      list-style: none;
      li {
        margin-bottom: 0.5rem;
        &::before {
          content: "•";
          color: red;
          display: inline-block;
          width: 1rem;
          margin-left: -1rem;
          margin-top: -0.2rem;
        }
      }
    }
    .iconwrappers {
      position: relative;

      h2 {
        @media ${device.mobile} {
          margin-bottom:0;
          padding-bottom:0;
        }
      }

      &.white {
        padding: 3rem 2rem 0.5rem 2rem;
        margin: 1rem;
        @media ${device.mobile} {
          padding-left: 0;
          margin-left: 0;
          margin-right: 0;
          margin-top: 2rem;
        }
        &:last-child {
          @media ${device.mobile} {
            margin-top: 5rem;
          }
        }
      }
    }
    .icons {
      background-color: #fff;
      height: 4px;
      position: relative;
      width: 40%;
      margin: 0 auto;
      top: -50px;
      @media ${device.mobile} {
        top: -39px;
      }

      img {
        position: relative;
        top: -35px;
        margin:0 auto;
        @media ${device.mobile} {
          top: -38px;
        }
      }
      &.icon2 {
        img {
          top: -20px;
          @media ${device.mobile} {
            top: -21px;
          }
        }
      }
    }
    .flexbtn {
      display: flex;
      justify-content: end;
      margin-bottom: 2.5rem;
    }
    a.btn-2 {
      width: 19.4375rem;
      &:after {
        line-height: 2rem;
        border-left: none;
        content: "";
      }
    }
    .treatment1 {
      width: 33%;
      text-align: center;
      & > h2 {
        margin-bottom: 4rem;
        @media ${device.mobile} {
          text-align:center;
        }
      }
      @media ${device.mobile} {
        width: 100%;
      }
    }

    .treatment2 {
      text-align: center;
      width: 67%;

      @media ${device.mobile} {
        width: 100%;
        margin-top: 3.3333rem;
      }

      &> h2 {
        margin-bottom: 4rem;
        @media ${device.mobile} {
          text-align: left;
        }
        @media ${device.tablet} {
         margin-left:-3rem;
        }
      }

      .calloutwrapper {
        display: flex;
        @media ${device.mobile} {
          flex-direction: column !important;
        }
        & > div {
          width: 50%;
          @media ${device.mobile} {
            width: 100%;
          }
        }
        }
      }
    }
  }


 

  #next-link {
    width: 18.75rem;
    @media ${device.mobile} {
      width: 19rem;
    }
    .btn-1 {
      padding: 0.7rem 2.75rem 0.7rem 1.5rem;
      width: 300px;
      @media ${device.mobile} {
        width: auto;
        padding: 0.7rem 1.75rem 0.7rem 1.5rem;
      }
    }
  }

  .footnote {
    &:first-of-type {
      margin-top: 2.5rem;
      margin-bottom: 6.5rem;
    }
  }

  .adverse-reactions-wrapper {
    @media ${device.mobile} {
      width: 100%;
      overflow-x: scroll;
    }
  }

  .scrolltext {
    margin-top: 1.6667rem;
    font-size: 1.33333rem;
  }

  .slick-dots {
    margin: 2rem 0 2.375rem 0;
    bottom:0;
    position:inherit;
    }
    .slick-arrow {
    bottom:-.375rem;
    }

      .transcript {
        margin-top:1.875rem;
      }
      .footnote {
        margin:0 0 1.25rem 0;
      }

      .bottom-footnotes {
        margin-top:6rem !important;
        @media ${device.mobile} {
          margin-top: 0 !important;
        }
      }
   
      #m-survey-management-container,#m-survey-mgnt-chart{
        display:none;
        @media ${device.mobile}{
          display:block;
        }
      }
`;

export const MobileSurveyMgntChartStyles = styled.div`
display: none;
margin: 1rem auto;

@media ${device.mobile} {
  display: block;
  margin-bottom: 2.333rem;
}
       

  & > div {
    align-items: stretch;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &:nth-of-type(1) {
      margin-bottom: 1rem;
    }

    & > a {
      align-items: center;
      display: flex;
      height: 5.5rem;
      justify-content: center;
      padding-left: 3.25rem;
      width: 48.75%;

      &[data-selector] {
        background-image: url("../images/increased-coughing-orange.svg");
        background-position: 7% 0.75rem;
        background-repeat: no-repeat;
        background-size: 3.25rem;
      }

      &[data-selector="one"] {
        background-image: url("../images/increased-coughing-white.svg");
        background-size: 28px 30px;
        background-position: 1rem;
      }
      &[data-selector="two"] {
        background-image: url("../images/dyspnea-white.svg");
        background-size: 23px 30px;
        background-position: 1rem;
      }
      &[data-selector="three"] {
        background-image: url("../images/dysphonia-white.svg");
        background-size: 23px 30px;
        background-position: 1rem;
      }
      &[data-selector="four"] {
        background-image: url("../images/increased-sputum-white.svg");
        background-size: 29px 30px;
        background-position: 1rem;
      }

      &.active {
        cursor: default;

        &[data-selector="one"] {
          background-image: url("../images/increased-coughing-orange.svg");
          background-size: 28px 30px;
          background-position: 1rem;
        }
        &[data-selector="two"] {
          background-image: url("../images/dyspnea-orange.svg");
          background-size: 23px 30px;
          background-position: 1rem;
        }
        &[data-selector="three"] {
          background-image: url("../images/dysphonia-orange.svg");
          background-size: 23px 30px;
          background-position: 1rem;
        }
        &[data-selector="four"] {
          background-image: url("../images/increased-sputum-orange.svg");
          background-size: 29px 30px;
          background-position: 1rem;
        }
      }
    }
  }

  #management-chart {
    display: flex;
    margin: 0 auto;
    margin-top: 2rem;
    padding: 0 10%;
    width: 100%;
    @media ${device.mobile} {
      padding: 0;
    }
  }
 
`;
